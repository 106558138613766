/*!
 * Copyright © 2025 FBSPL. All rights reserved.
 * This script is proprietary and confidential.
 * Unauthorized copying, distribution, or modification is strictly prohibited.
 */
// import { Component, Input, OnInit } from '@angular/core';
// import { RouterLink, Router } from '@angular/router';

// import { NoNumbersDirective } from '../../directives/noNumbers.directive';

// import { HomeApiService } from '../../services/homeApi/homeApi.service';

// @Component({
//   selector: 'ThinkingSection',
//   standalone: true,
//   templateUrl: './ThinkingSection.component.html',
//   imports: [RouterLink, NoNumbersDirective],
// })
// export class ThinkingSection implements OnInit {
//   @Input() category: string = '';
//   constructor(private router: Router, private HomeApiService: HomeApiService) {}

//   ApiData: any = {};
//   loading: boolean = true;

//   ngOnInit(): void {
//     this.HomeApiService.getOurThinkingData(this.category).subscribe(
//       (data) => {
//         this.ApiData = data;
//         this.loading = false;
//       },
//       (error) => {
//         console.error('Error fetching blog data', error);
//       }
//     );
//   }
// }

import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { NoNumbersDirective } from '../../directives/noNumbers.directive';
import { HomeApiService } from '../../services/homeApi/homeApi.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'ThinkingSection',
  standalone: true,
  templateUrl: './ThinkingSection.component.html',
  imports: [RouterLink, NoNumbersDirective],
})
export class ThinkingSection implements OnInit {
  @Input() category: string = '';
  ApiData: any = null;
  loading: boolean = true;
  error: string | null = null; // 🔹 Added error handling

  constructor(
    private router: Router,
    private homeApiService: HomeApiService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.homeApiService.getOurThinkingData(this.category).subscribe(
        (data) => {
          if (data) {
            this.ApiData = data;
          } else {
            this.error = 'No data available'; // 🔹 Handle case where API returns null
          }
          this.loading = false;
        },
        (error) => {
          console.error('Error fetching blog data', error);
          this.error = 'Error loading content'; // 🔹 Display error message instead of crashing
          this.loading = false;
        }
      );
    }
  }
}
