<!-- /*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/ -->
<section class="bg-primary py-5 position-relative">
  <div class="container">
    <h2 class="text-white font-48-30" data-aos="fade-up">{{ heading }}</h2>
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/AllCare.svg"
            alt="All Care Consultants, Inc "
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/alternative.svg"
            alt="Alternative Claim Management "
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chapman.svg"
            alt="Chapman Insurance Group"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/CRS.svg"
            alt="CRS Insurance Brokerage"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chrp.svg"
            alt="chrp"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/wg.svg"
            alt="Western Gold Insurance Agency"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/AllCare.svg"
            alt="All Care Consultants"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/alternative.svg"
            alt="Alternative Claim Management "
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chapman.svg"
            alt="Chapman Insurance Group"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/CRS.svg"
            alt="CRS Insurance Brokerage"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chrp.svg"
            alt="chrp"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/wg.svg"
            alt="Western Gold Insurance"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/AllCare.svg"
            alt="All Care Consultants"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/alternative.svg"
            alt="Alternative Claim Management "
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chapman.svg"
            alt="Chapman Insurance Group"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/CRS.svg"
            alt="CRS Insurance Brokerage"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chrp.svg"
            alt="chrp"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/wg.svg"
            alt="Western Gold Insurance"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/AllCare.svg"
            alt="All Care Consultants"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/alternative.svg"
            alt="Alternative Claim Management "
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chapman.svg"
            alt="Chapman Insurance Group"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/CRS.svg"
            alt="CRS Insurance Brokerage"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/chrp.svg"
            alt="chrp"
            height="150px"
            width="240px"
          />
        </div>
        <div class="trust-slide">
          <img
            class=""
            appCdnUrl="assets/services/assets-logos/wg.svg"
            alt="Western Gold Insurance"
            height="150px"
            width="240px"
          />
        </div>
      </div>
    </div>
  </div>
  <img
    appCdnUrl="assets/Icon/white-snake-vector.svg"
    class="position-absolute snakevector"
  />
</section>
