/*!
 * Copyright © 2025 FBSPL. All rights reserved.
 * This script is proprietary and confidential.
 * Unauthorized copying, distribution, or modification is strictly prohibited.
 */
import { Component, ElementRef, ViewChild, Input } from '@angular/core';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { YoutubeModalComponent } from '../../youtube-modal/youtube-modal.component';
import { VimeoPlayerComponent } from '../../vimeo-player/vimeo-player.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
declare var bootstrap: any;
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-know-best',
  standalone: true,
  imports: [
    SlickCarouselModule,
    VimeoPlayerComponent,
    YoutubeModalComponent,
    CdnUrlDirective,
  ],
  templateUrl: './know-best.component.html',
  styleUrl: './know-best.component.css',
})
export class KnowBestComponent {
  @ViewChild('iframe', { static: false }) iframe!: ElementRef;
  @ViewChild('iframeWrapper') iframeWrapper!: ElementRef;
  @ViewChild(YoutubeModalComponent) youtubeModal!: YoutubeModalComponent;
  @Input() cards: any[] = [];
  @Input() videos: any[] = [];

  currentVideoUrl!: string;
  currentThumbnail!: string;
  currentalt!: string;

  isMobileView: boolean = true;
  isPopupVisible: boolean = false;
  cdnUrl = environment.cdnUrl;
  constructor(
    public sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    arrow: false,
  };

  ngOnInit() {
    if (this.videos.length > 0) {
      this.currentVideoUrl = this.videos[0].videoUrl;
      this.currentThumbnail = this.videos[0].thumbnail;
      this.currentalt = this.videos[0].alt;
    }
  }
  togglePopup(videoUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.youtubeModal) {
        this.youtubeModal.youtubeUrl = videoUrl;
        this.youtubeModal.updateSafeUrl();
        const modalElement = document.getElementById('youtubeModal');
        if (modalElement) {
          const modal = new bootstrap.Modal(modalElement);
          modal.show();
        }
      }
    }
  }

  onAfterChange(event: any) {
    if (this.videos.length > 0) {
      this.currentVideoUrl = this.videos[event.currentSlide].videoUrl;
      this.currentThumbnail = this.videos[event.currentSlide].thumbnail;
      this.currentalt = this.videos[event.currentSlide].alt;

      if (this.iframeWrapper) {
        this.iframeWrapper.nativeElement.classList.remove('zoom-in-out');
        void this.iframeWrapper.nativeElement.offsetWidth;
        this.iframeWrapper.nativeElement.classList.add('zoom-in-out');
      }
    }
  }
}
