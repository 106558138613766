/*!
 * Copyright © 2025 FBSPL. All rights reserved.
 * This script is proprietary and confidential.
 * Unauthorized copying, distribution, or modification is strictly prohibited.
 */
// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';

// import { environment } from '../../../environments/environment';
// @Injectable({
//   providedIn: 'root',
// })
// export class HomeApiService {
//   private apiUrl = environment.apiUrl;

//   constructor(private http: HttpClient) {}

//   getOurThinkingData(category: string): Observable<any> {
//     return this.http.get<any>(`${this.apiUrl}/api/home/data?category=${category}`);
//   }
// }

// 17/2/25

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, shareReplay, catchError } from 'rxjs/operators'; // 🔹 Added

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeApiService {
  private apiUrl = environment.apiUrl;
  private cache: { [key: string]: Observable<any> } = {}; // 🔹 Added caching object

  constructor(private http: HttpClient) {}

  getOurThinkingData(category: string): Observable<any> {
    if (this.cache[category]) {
      return this.cache[category]; // 🔹 Return cached response if available
    }

    this.cache[category] = this.http
      .get<any>(`${this.apiUrl}/api/home/data?category=${category}`)
      .pipe(
        shareReplay(1), // 🔹 Prevents duplicate API requests
        catchError((error) => {
          console.error('API Fetch Error:', error);
          delete this.cache[category]; // 🔹 Remove cache on error to allow retry
          return of(null); // 🔹 Return null to prevent SSR crash
        }),
        tap(() => console.log(`API Fetched: ${category}`)) // 🔹 Log API calls
      );

    return this.cache[category];
  }
}
