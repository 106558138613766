/*!
 * Copyright © 2025 FBSPL. All rights reserved.
 * This script is proprietary and confidential.
 * Unauthorized copying, distribution, or modification is strictly prohibited.
 */
import { Component, Input } from '@angular/core';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';

@Component({
  selector: 'app-ourAssets',
  standalone: true,
  imports: [CdnUrlDirective],
  templateUrl: './ourAssets.component.html',
  styleUrls: ['./ourAssets.component.css'],
})
export class ourAssetsComponent {
  @Input() heading: string = 'Our clients';
}
